import * as React from 'react';
import PropTypes from 'prop-types';

const PrimaryPortfolioCarousel = (props) => {
  const { data } = props;
  const portfolioContent = data.nodes.filter((item) => item.portfolioType === 'primary');

  return (
    <div style={{ 'backgroundColor': '#e24e42' }}>
      <div className="md:px-4 pt-12 pb-12">
        <div className="container mx-auto font-sans pl-4 md:pl-4 lg:w-5/6 md:p-4">
          <h3 className="dark:text-white text-3xl md:text-4xl font-bold pb-2 ">Producties</h3>
        </div>
        <div className="overflow-x-scroll overflow-y-hidden whitespace-nowrap pt-4  ">
          {portfolioContent.map((portfolioItem) => (
            <a key={portfolioItem.name} href={portfolioItem.link} target="_blank">
              <div
                className="inline-block mr-3 pl-2 relative bg-cover bg-no-repeat bg-center transition duration-500 hover:scale-105 transform"
                style={{
                  width: '300px', height: '300px', backgroundSize: 'cover', backgroundImage: `url(https:${portfolioItem.image.file.url}) `,
                }}
              >
                <span className="text-white font-bold shadow-lg text-lg bg-black p-1 inline-block absolute top-4">{portfolioItem.toBeViewedAt}</span>
                <div className="absolute bottom-4">
                  <div className="whitespace-normal pb-0 pr-6">
                    <h4 className="inline leading-10 text-white font-bold shadow-xl text-2xl bg-black black-bg-fix py-1 my-0.5 block">
                      {portfolioItem.name}
                    </h4>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

PrimaryPortfolioCarousel.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.objectOf(
        PropTypes.shape({
          file: PropTypes.string,
        }),
      ),
      name: PropTypes.string,
      toBeViewedAt: PropTypes.string,
      filter: PropTypes.func,
    })),
  ).isRequired,
};

export default PrimaryPortfolioCarousel;
