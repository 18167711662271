import * as React from 'react';
import PropTypes from 'prop-types';

const Contact = (props) => {
    const { data } = props;
    return (
        <div className="bg-yellow-100" style={{ backgroundColor: '#e24e42' }}>
            <div className="md:px-4 pt-12 pb-12">
                <div className="container mx-auto font-sans pl-4 md:pl-4 lg:w-5/6 md:p-4">
                    <h3 className="dark:text-white text-3xl md:text-4xl font-bold pt-4 pb-4 ">Contact</h3>
                    <p className="text-white text-lg">Voor contact, stuur een email naar <a className="hover:underline" href="mailto:puck@talentkitchen.nl">puck@talentkitchen.nl</a> of bel naar 020 808 6000.</p>
                </div>
            </div>
        </div>
    );
};


export default Contact;
