import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Header from '../components/Header';
import PrimaryPortfolioCarousel from '../components/PrimaryPortfolioCarousel';
import Bio from '../components/Bio';
import SecondaryPortfolioCarousel from '../components/SecondaryPortfolioCarousel';
import PhotoGrid from '../components/PhotoGrid';
import Contact from '../components/Contact';

export const query = graphql`
query AboutQuery {
  contentfulHeader(contentful_id: { eq: "nPls6dxDDqLGMlSyStfEB" }) {
    name,
    introText,
    profilePicture {
      file {
        url
      }
    }
  },
  contentfulAboutme(contentful_id: { eq: "7CbgzQEV10XwOr9AeNNuLn" }) {
    aboutMeTitle,
    description {
      description
    },
  },
  contentfulPhotoGrid(contentful_id: { eq: "6VIcAyzBbY5ubKvZtMXVHd" }) {
    photo {
      file {
          url
      }
    },
  },
  allContentfulTvshows {
    nodes {
      name,
      toBeViewedAt,
      portfolioType,
      link,
      image {
        file {
          url
        }
      }
    }
  }
}
`;

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Header data={props.data.contentfulHeader} />
      <PrimaryPortfolioCarousel data={props.data.allContentfulTvshows} />
      <SecondaryPortfolioCarousel data={props.data.allContentfulTvshows} />
      <Bio data={props.data.contentfulAboutme} />
      <PhotoGrid data={props.data.contentfulPhotoGrid} />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
