import * as React from 'react';
import PropTypes from 'prop-types';

const Bio = (props) => {
  const { data } = props;
  return (
    <div className="bg-yellow-100" style={{ backgroundColor: '#eb6e80' }}>
      <div className="md:px-4 pt-12 pb-12">
        <div className="container mx-auto font-sans pl-4 md:pl-4 lg:w-5/6 md:p-4">
          <h3 className="dark:text-white text-3xl md:text-4xl font-bold pt-4 pb-8 ">{data.aboutMeTitle}</h3>
          <p className="dark:text-white text-xl leading-relaxed">{data.description.description}</p>
        </div>
      </div>
    </div>
  );
};

Bio.propTypes = {
  data: PropTypes.shape({
    contentful_id: PropTypes.string,
    aboutMeTitle: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
};

export default Bio;
