import * as React from 'react';
import PropTypes from 'prop-types';

const SecondaryPortfolioCarousel = (props) => {
  const { data } = props;
  const portfolioContent = data.nodes.filter((item) => item.portfolioType === 'secondary');
  return (
    <div style={{ backgroundColor: '#e9b000' }}>
      <div className="md:px-4 py-12">
      <div className="container mx-auto font-sans pl-4 md:pl-4 lg:w-5/6 md:p-4">
          <h3 className="dark:text-white text-3xl md:text-4xl font-bold pb-2 ">Overige producties</h3>
        </div>
        <div className="overflow-x-scroll overflow-y-hidden whitespace-nowrap pt-4  ">
          {portfolioContent.map((portfolioItem) => (
            <a href={portfolioItem.link} key={portfolioItem.name} target="_blank">
              <div className="inline-block mr-3 relative bg-cover bg-no-repeat bg-center transition duration-500 minicard transform hover:scale-105" style={{ backgroundSize: 'cover', backgroundImage: `url(https:${portfolioItem.image.file.url})` }}>
                <div className="absolute top-0 px-1 bg-black m-1 ">
                  <span className="inline text-white text-lg">{portfolioItem.toBeViewedAt}</span>
                </div>
                <div className="absolute bottom-0 px-1 block whitespace-normal m-1">
                  <span className="inline text-white black-bg-fix bg-black font-white text-lg font-bold">{portfolioItem.name}</span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

SecondaryPortfolioCarousel.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.objectOf(
        PropTypes.shape({
          file: PropTypes.string,
        }),
      ),
      name: PropTypes.string,
      toBeViewedAt: PropTypes.string,
      filter: PropTypes.func,
    })),
  ).isRequired,
};
export default SecondaryPortfolioCarousel;
