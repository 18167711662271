import * as React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { data } = props;
  return (
    <div style={{ backgroundColor: '#92C2A6' }}>
      <div className="text-center container mx-auto px-4 md:px-0 font-sans lg:w-3/4 pt-16 pb-24 lg:pt-8 lg:pb-8">
        <div className="block">
          <img className="inline-block h-44 w-44 rounded-full" src={data.profilePicture.file.url} alt="" />
        </div>
        <div className="block">
          <h1 className="text-3xl md:text-4xl font-bold pt-8">
            {data.name}
            {' '}
            hier!
            {' '}
            <span className="wave">👋</span>
          </h1>
        </div>
        <div className="lg:w-3/4 m-auto container mx-auto px-4 md:px-0 font-sans ">
          <h3 className="text-center text-black text-2xl md:text-2xl pt-8   ">{data.introText}</h3>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    profilePicture: PropTypes.object,
    name: PropTypes.string.isRequired,
    introText: PropTypes.string,
  }).isRequired,
};

export default Header;
